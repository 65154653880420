import React, { useState } from "react";
import "./Header.css";
import Menu from "./menu";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";

const logo =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/logopeteyetm.webp";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [homeSubmenuOpen, setHomeSubmenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    if (!isToggled) {
      window.location.href = "https://dashboard.peteye.pet";
      
    } else {
      window.location.href = "https://www.peteye.pet/";
    }
  };

  return (
    <div>
      <header className="mainHeader">
        <div>
          <img className="logo" src={logo} alt="peteye logo" title="" /> 
        </div>

        {/* Menu Links */}
        <div className="links">
          <div
            className={`toggle-button ${isToggled ? "toggled" : ""}`}
            onClick={handleToggle}
          >
            <div
              className={`toggle-circle ${isToggled ? "right" : "left"}`}
            ></div>
            <div className="toggle-icons">
              <IoEarth className={`icon1 ${isToggled ? "active" : ""}`} />
              <FaUser className={`icon2 ${!isToggled ? "active" : ""}`} />
            </div>
          </div>
          <div class="menu-wrapper">
            <input
              type="checkbox"
              name="check"
              id="check"
              onClick={toggleMenu}
              className="menubtn2"
            />
            <label for="check" class="hamburger-menu-container">
              <div class="hamburger-menu">
                <div></div>
              </div>
            </label>
          </div>

          {menuOpen && <Menu />}
        </div>
      </header>
    </div>
  );
};

export default Header;
