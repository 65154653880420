import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./LandingPage.css";
import ImageCard from "./components/ImageCard";

import VideoModal from "./components/VideoModal";
import AOS from "aos";
import { useTitle } from "../../hooks/useTitle";

const petEyeImg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Pet%2BEYE.webp ",
  mainImg1 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame%2B1171277161.webp",
  mainImg2 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame%2B1171277162.webp",
  mainImg3 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame%2B1171277159.webp",
  mainImg4 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame%2B1171277163.webp",
  mainImg5 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame%2B1171277164.webp",
  sec2Img1 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/856D846D-9FE2-4FEE-8593-824B0CDFF32D.webp",
  sec2Img2 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/372DCD32-B9D4-41DA-8166-C6D2517D40CE.webp",
  wheelImg =
    "https://res.cloudinary.com/djweedhpy/image/upload/v1710762733/Landing%20page/desktop/Group_1171277035_aceovc.png",
  sec5Img =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Banner_Image.webp",
  mobileImg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/blinking-eyephone.webp",
  playStoreBadge =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/google-play-badge+1.webp",
  appStoreBadge =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/app-store-badge%2B1.webp";

const defaultVid =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Video1.mp4";

export default function LandingPage() {
  useTitle("PetEYE: Data and Health Management Services.");
  const sec_3_VideoDefault =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Onboard.mp4";
  const [videoToPlay, setVideoToPlay] = useState(null);
  const [isHovered, setHovered] = useState(false);
  const [sec3Vid, setSec3Vid] = useState(sec_3_VideoDefault);
  const [imageRotation, setImageRotation] = useState(0);
  useEffect(() => {
    AOS.init({ duration: 500, delay: 50 });
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const playVideo = (src) => {
    setVideoToPlay(src);
  };
  const videoImages = [
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Group+1171277084.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Group+1171277086(1).webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Group+1171277091(1).webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Group+1171277085(1).webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Group+1171277090(1).webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Group+1171277089(1).webp",
  ];
  const videos = [
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Onboard.mp4",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/L%2B%26%2BF%2B.mp4",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/add%2Bfamily.mp4",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/reminder.mp4",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Add%2Bdocument%2B.mp4",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Add%2Bpet%2B.mp4",
  ];
  useEffect(() => {
    const imageRotationInterval = setInterval(() => {
      setImageRotation((prevRotation) => prevRotation - 15);
    }, 3000);
    return () => clearInterval(imageRotationInterval);
  }, []);
  console.log(imageRotation);

  const [rotationIndex, setRotationIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotationIndex((prevIndex) => (prevIndex + 1) % 21);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const step = 360 / 21;
  const imageUrls = [
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277285.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277286.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277287.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277289.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277291.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277290.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277288.webp",
  ];

  const linkUrls = [
    "https://g.co/kgs/8C7oX5f",
    "https://g.co/kgs/aAC8GGz",
    "https://g.co/kgs/PDjcnf9",
    "https://maps.app.goo.gl/ApDoviV7A3nETvXT8",
    "https://g.co/kgs/Md34hcb",
    "https://g.co/kgs/kDFrnm1",
    "https://g.co/kgs/dgtdrRP",
  ];

  return (
    <div className="main landing scroll-snap">
      <Header></Header>
      <section className="home-main-sec home-sec">
        <div className="home-head-tag" data-aos="fade-up">
          <div className="home-head">
            <img
              src={petEyeImg}
              alt="peteye"
              title=""
              className="peteye-img"
              data-aos-delay="200"
            />
          </div>
          <span>Nurturing Bonds Ensuring Safety</span>
        </div>
        <div className="home-main-img-row container">
          <div
            className="home-main-img-row-item"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="750"
          >
            <img src={mainImg1} alt="dog care" title="" />
          </div>
          <div
            className="home-main-img-row-item"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <img src={mainImg2} alt="pet care" title="" />
          </div>
          <div
            className="home-main-img-row-item"
            data-aos="fade-up"
            data-aos-delay="0"
          >
            <img src={mainImg3} alt="pet health" title="" />
          </div>
          <div
            className="home-main-img-row-item"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <img src={mainImg4} alt="dog care" title="" />
          </div>
          <div
            className="home-main-img-row-item"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="750"
          >
            <img src={mainImg5} alt="dog care" title="" />
          </div>
        </div>
      </section>
      <section className="home-sec-2 home-sec container">
        <div className="sec-title" data-aos="fade-up">
          <span data-aos="fade-up">What we do</span>
        </div>
        <div className="sec-header" data-aos="fade-up">
          <h3 data-aos="fade-up">
            Effortlessly meet your pet's needs with our user-friendly app.
          </h3>
        </div>
        <div className="sec-containers">
          <div className="sec-container" data-aos="fade-up">
            <div className="sec-container-1-img">
              <img
                data-aos="zoom-out-down"
                src={sec2Img1}
                alt="pet needs"
                title=""
              />
            </div>
            <div className="sec-container-header" data-aos="fade-up">
              <h5>Experience data management and track progress</h5>
              <h6 className="sec-container-desc">
                Effortlessly monitor your progress with complimentary data
                management services, ensuring streamlined organization and
                optimization of your data.
              </h6>
            </div>
          </div>
          <div
            className="sec-container"
            style={{ marginTop: "-55px" }}
            data-aos="fade-up"
          >
            <div className="sec-container-2-img">
              <img
                data-aos="zoom-out-down"
                src={sec2Img2}
                alt="pet reminders"
                title=""
              />
            </div>
            <div className="sec-container-header" data-aos="fade-up">
              <h5>Set Reminders and updates</h5>
            </div>
            <div className="sec-container-desc" data-aos="fade-up">
              Access reminders and updates via our complimentary data management
              services for seamless progress tracking.
            </div>
            <div className="sec-container-btn" data-aos="fade-up"></div>
          </div>
        </div>
      </section>
      <section className="home-sec-5">
        <div className="home-sec-5-container">
          <h1 data-aos="fade-up" className="home-sec-4-head">
            Welcome to new era & Discover ultimate pet care experience
          </h1>
          <div
            data-aos="fade-up"
            className="sec-5-img-container"
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isHovered ? (
              <video autoPlay loop muted controls className="sec-5-video">
                <source src={defaultVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={sec5Img} alt="peteye video" title="" />
            )}
          </div>
        </div>
      </section>
      <section className="home-sec-3 home-sec container" id="sec-3">
        <div className="home-sec-3-row-1">
          <div className="home-sec-3-row-1-container">
            <h2 data-aos="fade-up">
              Creating a safe space for your pet with PetEYE
            </h2>
            <h6 data-aos="fade-up">
              Explore features like reminders and health tracking to ensure your
              pet's well-being. Don't forget to warmly engage with the 'Add Your
              Pet' document section. Welcome to a new era of pet parenting!"
            </h6>
          </div>
          <div className="home-sec-3-row-1-container">
            <div className="video">
              <video
                key={sec3Vid}
                data-aos="zoom-out-down"
                autoPlay
                loop
                muted
                controls
                width="100%"
                height="auto"
                alt="vid"
                title=""
              >
                <source src={sec3Vid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="sec-title mt-5" data-aos="fade-up">
          <span>Get familiar with our features</span>
        </div>
        <div className="home-sec-3-row-3">
          <div className="home-sec-3-row-3-container mt-5">
            {[0, 0, 0, 0, 0, 0].map((im, index) => {
              return (
                <ImageCard
                  src={videoImages[index]}
                  onClick={() => {
                    setSec3Vid(videos[index]);
                    window.location.href = "#sec-3";
                  }}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="home-sec-6">
        <div className="home-sec-6-container container">
          <div className="sec-6-img-container">
            <img
              src={mobileImg}
              data-aos="fade-up"
              alt="mobile peteye logo"
              title=""
            />
          </div>
          <div className="home-sec-6-content">
            <h2 className="home-sec-6-head" data-aos="fade-up">
              Enhance Your Experience Download Our App from the App Store or
              Play Store{" "}
            </h2>
            <div className="sec-6-mobile-image">
              <img
                src={mobileImg}
                alt="mobile peteye logo"
                title=""
                data-aos="fade-up"
              />
            </div>
            <h6 className="home-sec-6-para" data-aos="fade-up">
              "Discover a world of convenience and joy with our PetEye app!
              Seamlessly manage your pet's needs, connect with fellow pet
              lovers, and explore exclusive features tailored for you and your
              furry companion. Elevate your pet parenting journey – download now
              from the App Store or Play Store!"
            </h6>
            <div className="btns">
              <a
                href="https://play.google.com/store/apps/details?id=com.peteye.app&pli=1"
                target="_blank"
              >
                {" "}
                <img
                  data-aos="fade-up"
                  src={playStoreBadge}
                  alt="peteye playstore app"
                  title=""
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/peteye/id6450916668"
                target="_blank"
              >
                {" "}
                <img
                  data-aos="fade-up"
                  src={appStoreBadge}
                  alt="peteye appstore app"
                  title=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="home-sec-4 .home-sec container">
        <div className="home-sec-4-row-1" data-aos="fade-up">
          <h4>
            "PetEye Reviews: Heartfelt Reviews from our furry “Friends & Family”{" "}
          </h4>
        </div>
        <div className="sec-title text-center mt-5">
          <span data-aos="fade-up">Here We Go</span>
        </div>
      </section>
      <section>
        <div className="circleCarousel">
          <div
            className="circle"
            style={{
              "--items": 21,
              "--rotation": `${step * rotationIndex * -1}deg`,
            }}
          >
            {[...Array(21)].map((_, index) => (
              <a
                key={index}
                href={linkUrls[index % linkUrls.length]}
                className="image-anchor"
                target="_blank"
                style={{ "--index": index }}
              >
                <div
                  key={index}
                  className="image"
                  style={{
                    "--index": index,
                    backgroundImage: `url(${
                      imageUrls[index % imageUrls.length]
                    })`,
                  }}
                ></div>
              </a>
            ))}
          </div>
        </div>
      </section>
      <VideoModal
        videoSrc={videoToPlay}
        setVideoSrc={setVideoToPlay}
      ></VideoModal>
      <section>
        <Footer />
      </section>
    </div>
  );
}
