import React, { useEffect, useState, useCallback } from "react";
import Header from "../../Components/Header/Header";
import { RiSearch2Fill } from "react-icons/ri";
import { FaSquareXmark } from "react-icons/fa6";
import { MdHeadsetMic } from "react-icons/md";
import { LuMessagesSquare } from "react-icons/lu";
import { TbMessageQuestion } from "react-icons/tb";
import { PiShoppingCartBold } from "react-icons/pi";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import axios from "../../axios";
import faqJson from "../../assets/json/faq.json";
import "./SupportPage.css";
import { useParams } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";

const mainImg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Support/b477f547-f202-44e5-9b18-c480bb06b05c+1.png",
  doggif1 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/dog-thinking.webp",
  doggif2 =
    //dog
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/dog.webp",
  doggif3 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/dog-question.webp";

export default function SupportPage() {
  useTitle("PetEYE Search: Hi, I am PEARL, Ask me anything related to pets?");
  const PetData = faqJson;
  const { section } = useParams();

  const [query, setQuery] = useState("");
  const [showGif1, setShowGif1] = useState(false);
  const [filteredFAQs, setFilteredFAQs] = useState([]);
  const [isFormFixed, setIsFormFixed] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [showSupportBlogs, setShowSupportBlogs] = useState(false);
  const [products, setProducts] = useState([]);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const trimmedValue = value.trim();

    // Update state based on input value
    setQuery(value);
    setShowGif1(true);
    filterFAQs(value);
    debounceHideGif();
    setShowSupportBlogs(trimmedValue.length > 0);
    setIsScrolling(true);

    // Set isFormFixed based on input length
    if (value.length > 0) {
      setIsFormFixed(true);
    } else {
      setIsFormFixed(false);
    }

    // Perform debounced search if the trimmed value is not empty
    if (trimmedValue.length > 0) {
      debouncedSearch(trimmedValue);
    } else {
      setProducts([]);
    }
  };

  // Custom debounce function
  const debounce = (func, delay) => {
    let debounceTimeout;
    return function (...args) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Debounced search function
  const debouncedSearch = debounce((value) => {
    axios
      .get("/web/searchproduct", {
        params: { ProductName: value },
      })
      .then((response) => {
        console.log("API response:", response);
        if (response.data.status === "200") {
          setProducts(response.data.message);
        } else {
          setProducts([]);
        }
      })
      .catch((error) => {
        console.error("Error searching:", error);
        setProducts([]);
      });
  }, 100);

  const handleClear = () => {
    setQuery("");
    setShowGif1(false);
    setFilteredFAQs([]);
    setShowSupportBlogs(false);
    setIsScrolling(false);
    setProducts([]);
    setNoResults(false);
    setIsFormFixed(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitted query:", query);
    setShowSupportBlogs(true);
  };

  const filterFAQs = (query) => {
    if (query.length > 0) {
      const words = query.toLowerCase().split(/\s+/);
      const results = faqJson.filter((faq) => {
        const question = faq.Question ? faq.Question.toLowerCase() : "";
        const answer = faq.Answer ? faq.Answer.toLowerCase() : "";

        return words.some(
          (word) => question.includes(word) || answer.includes(word)
        );
      });

      setFilteredFAQs(results);
      setNoResults(results.length === 0);
    } else {
      setFilteredFAQs([]);
      setNoResults(false);
    }
  };

  const debounceHideGif = useCallback(
    debounceIt(() => setShowGif1(false), 500),
    []
  );

  function debounceIt(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }


  const getValidProducts = (products) => {
    const length = products.length;
    if (length === 2 || length >= 4) {
      return products.slice(0, 4);
    } else if (length === 3) {
      return products.slice(0, 2);
    }
    return [];
  };

  const storePage = () => {
    window.location.href = "https://dashboard.peteye.pet/store";
  };

  const validProducts = getValidProducts(products);

  return (
    <div className="main">
      <Header />
      <div>
        <section>
          <section>
            {!isFormFixed && (
              <div className="support-dogheader">
                <img
                  src={noResults ? doggif3 : showGif1 ? doggif1 : doggif2}
                  alt="think-peteye"
                  title=""
                />
                <h1>
                  {noResults
                    ? "Oh! no. There’s no answer for your question"
                    : "Hi I'm PEARL, Ask me anything related to pets?"}
                </h1>
              </div>
            )}

            <div className="form" style={{ marginBottom: "8rem" }}>
              <form
                onSubmit={handleSubmit}
                className={`search-form ${isFormFixed ? "fixed" : ""}`}
              >
                <button type="submit" className="icon-button search-icon">
                  <RiSearch2Fill
                    style={{ fontSize: "1.8rem", color: "#B1B1B1" }}
                  />
                </button>
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={handleInputChange}
                  value={query}
                  className="search-input"
                />
                {query && (
                  <button
                    type="button"
                    className="icon-button clear-icon"
                    onClick={handleClear}
                  >
                    <span role="img" aria-label="clear">
                      <FaSquareXmark
                        style={{ fontSize: "1.5rem", color: "#B1B1B1" }}
                      />
                    </span>
                  </button>
                )}
                <button type="button" className="icon-button voice-icon">
                  <span role="img" aria-label="voice">
                    <MdHeadsetMic
                      style={{ fontSize: "1.8rem", color: "#B1B1B1" }}
                    />
                  </span>
                  &ensp;
                  <span role="img" aria-label="voice">
                    <LuMessagesSquare
                      style={{
                        fontSize: "1.8rem",
                        color: "#B1B1B1",
                        fill: "#B1B1B1",
                      }}
                    />
                  </span>
                </button>
              </form>
            </div>
            {showSupportBlogs && (
              <div className="support-blogs">
                <div
                  className={`support-questions ${
                    noResults ? "center-products" : ""
                  }`}
                >
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}
                  >
                    <Masonry>
                      {filteredFAQs.length > 0 && (
                        <div className="faq">
                          <h2>{filteredFAQs[0].Question}</h2>
                          <div className="support-answers">
                            <div className="support-answers-header">
                              <TbMessageQuestion
                                style={{ fontSize: "1.8rem", color: "#000" }}
                              />
                              <h3> Answer</h3>
                            </div>
                            <p>{filteredFAQs[0].Answer}</p>
                          </div>
                        </div>
                      )}
                      {products.length > 0 && (
                        <div className="support-products">
                          <div className="product-header">
                            <PiShoppingCartBold
                              style={{ fontSize: "2rem", color: "#000" }}
                            />
                            <p> Related Products</p>
                          </div>
                          <div className="product-grid">
                            {validProducts.map((product, index) => {
                              return (
                                <div
                                  key={index}
                                  className="product-item"
                                  onClick={storePage}
                                >
                                  <img
                                    src={product.productImagemodel?.[0]?.url}
                                    alt={product?.ProductName}
                                  />
                                  <p>{product.ProductName}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {filteredFAQs.slice(1).map((faq, index) => (
                        <div className="faq" key={index + 1}>
                          <h2>{faq.Question}</h2>
                          <div className="support-answers">
                            <div className="support-answers-header">
                              <TbMessageQuestion
                                style={{ fontSize: "1.8rem", color: "#000" }}
                              />
                              <h3> Answer</h3>
                            </div>
                            <p>{faq.Answer}</p>
                          </div>
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              </div>
            )}
          </section>
        </section>
      </div>
    </div>
  );
}
