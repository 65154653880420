import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import './Login.css'
function Login() {
  const [username, setusername] = useState('')
  const [password, setpassword] = useState('')
  const [refresh, setrefresh] = useState(false)
  const [err, seterr] = useState('')

  const dispatch = useDispatch()

  const handleSubmit=(e)=>{
    e.preventDefault()
    if(username.trim() && password.trim()){
      dispatch({ type: 'admin', payload: { username:username,password:password } });
      setrefresh(!refresh)
    }else{
      seterr('All field required')
    }
  }

  useEffect(() => {
    
  }, [refresh])
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='Login'>
          <div className='Login-sub'>
            <input type="text" placeholder='username' value={username} onChange={(e)=>setusername(e.target.value)} />
            <input type="password" placeholder='password' value={password} onChange={(e)=>setpassword(e.target.value)} />
            <button type='submit'>Login</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
