import React, { useEffect } from "react";
import "./Policy.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function Policy() {
  const { section } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="policy">
        <section>
          <div className="policysec1" id="policy1">
            <h1>Privacy Policy</h1>
          </div>
        </section>
        <div className="description1">
          <h2>SECTION 1 – INFORMATION COLLECTION</h2>
          <div>
            <h4>1.1 Personal Information Collection:</h4>
            <p>
              When you make a purchase from K R PET EYE LLP, we collect the
              personal information you provide, including your name, address,
              and email address. Additionally, when you browse our store, we
              automatically receive your computer’s IP address, which helps us
              understand your browser and operating system.
            </p>
            <p>
              We also collect information about the individual web pages or
              products you view, what websites or search terms referred you to
              the Site, and information about how you interact with the Site. We
              refer to this automatically-collected information as “Device
              Information.” We collect Device Information using the following
              technologies:
            </p>
            <ul>
              <li>
                <strong>Cookies</strong>: Data files that are placed on your
                device or computer and often include an anonymous unique
                identifier.
              </li>
              We may collect information from other sources:
              <li>
                Price comparison websites, if you have obtained a quotation for
                a policy with us.
              </li>
              <li>
                Third-party service providers with whom we have a relationship.
              </li>
              <li>
                Publicly available sources of information, such as social media
                and networking sites.
              </li>
              <li>
                Third-party databases made available to the insurance industry,
                as well as databases where you have given your permission to
                share information with a third party like us, which include but
                are not limited to fraud databases.
              </li>
            </ul>
            <p>
              <strong>Data Retention:</strong> When you place an order through
              the Site, we will maintain your Order Information for our records
              unless and until you ask us to delete this information.
            </p>

            <h5>1.2 Marketing:</h5>
            <p>
              With your explicit permission, we may send you information about
              our pet products, new arrivals, and other relevant updates,
              including but not limited to:
            </p>
            <ul>
              <li>Emails</li>
              <li>Internet, mobile, and other digital marketing</li>
              <li>Operating a Refer a Friend scheme</li>
            </ul>
            <h5>Behavioral Remarketing:</h5>
            <p>
              We may use remarketing services to advertise on third-party
              websites to you after you visit our Service. We and our
              third-party vendors use cookies to inform, optimize and serve ads
              based on your past visits to our Service.
            </p>
          </div>

          <div>
            <h4>SECTION 2 – CONSENT</h4>

            <h5>2.1 Obtaining Your Consent:</h5>
            <p>
              We obtain your consent when you provide personal information for
              specific purposes, such as completing a transaction, verifying
              payment details, placing an order, arranging delivery, or
              initiating a return.
            </p>

            <h5>2.2 Secondary Purposes:</h5>
            <p>
              If we need your personal information for secondary purposes like
              marketing, we will either directly request your consent or provide
              you with the option to decline.
            </p>

            <h5>2.3 Withdrawing Consent:</h5>
            <p>
              You may withdraw your consent at any time by contacting us at{" "}
              <a href="mailto:care@peteye.pet">care@peteye.pet</a> or by mailing
              us at K R PET EYE LLP, 15th Street, Himayat Nagar, Hyderabad,
              500029.
            </p>
          </div>
          <div>
            <h4>SECTION 3 – INFORMATION DISCLOSURE</h4>
            <p>
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <ul>
              <li>
                <strong>Disclosure for Law Enforcement:</strong> Under certain
                circumstances, we may be required to disclose your Personal Data
                if required to do so by law or in response to valid requests by
                public authorities.
              </li>
              <li>
                <strong>Business Transaction:</strong> If we or our subsidiaries
                are involved in a merger, acquisition, or asset sale, your
                Personal Data may be transferred.
              </li>
              <li>
                <strong>Other Cases:</strong> We may disclose your information
                also:
                <ul>
                  <li>To our subsidiaries and affiliates</li>
                  <li>
                    To contractors, service providers, and other third parties
                    we use to support our business
                  </li>
                  <li>To fulfill the purpose for which you provide it</li>
                  <li>
                    For the purpose of including your company’s logo or photos
                    on our website
                  </li>
                  <li>
                    For any other purpose disclosed by us when you provide the
                    information
                  </li>
                  <li>With your consent in any other cases</li>
                  <li>
                    If we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of the Company, our
                    customers, or others
                  </li>
                  <li>
                    Insurers, third-party underwriters, reinsurers, insurance
                    intermediaries, regulators, law enforcement, the Financial
                    Ombudsman Service (FOS), and the Office of the Arbiter for
                    Financial Services
                  </li>
                  <li>
                    Price comparison websites (which you may have used to
                    purchase your policy)
                  </li>
                  <li>Claims third-party administrators</li>
                  <li>Complaint third-party administrators</li>
                  <li>
                    Approved suppliers and third parties we are contracted with,
                    who provide a service related to the insurance policy
                  </li>
                  <li>Loss adjusters</li>
                  <li>Regulatory authorities</li>
                  <li>Fraud prevention agencies</li>
                  <li>Legal or crime prevention agencies</li>
                  <li>
                    Any additional insured parties who may communicate with us
                    on your behalf, provided that they have your permission
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div>
            <h4>SECTION 4 – PAYMENT PROCESSING</h4>
            <p>
              For payment processing, we utilize the services of Razorpay
              Software Private Limited (“Razorpay”). Neither K R PET EYE LLP nor
              Razorpay store your card data on their servers. Payment
              information is encrypted in accordance with the Payment Card
              Industry Data Security Standard (PCI-DSS) during payment
              processing. We retain your purchase transaction data only as long
              as necessary to complete the transaction. Our payment gateway
              adheres to PCI-DSS standards, ensuring secure handling of credit
              card information. For detailed information, you can review the
              terms and conditions of RazorPay at{" "}
              <a href="https://razorpay.com/terms/" target="_blank">
                https://razorpay.com/terms/
              </a>
              .
            </p>
          </div>

          <div>
            <h4>SECTION 5 – THIRD-PARTY SERVICES</h4>
            <p>
              Our third-party service providers collect, use, and disclose your
              information solely for the services they provide us. Payment
              gateways and transaction processors have their own privacy
              policies governing the information we provide for purchase-related
              transactions. We recommend reviewing these policies for a full
              understanding of how your personal information is handled. Please
              note that some providers may operate in different jurisdictions,
              subjecting your information to the laws of those jurisdictions
              when you use their services. Leaving our website or being
              redirected to a third-party site means you are no longer governed
              by our Privacy Policy or Terms and Conditions. We strongly advise
              you to review the Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies, or practices of any third-party sites
              or services.
            </p>
            <p>
              <strong>Service Providers:</strong> We may employ third-party
              companies and individuals to facilitate our Service (“Service
              Providers”), provide Service on our behalf, perform
              Service-related services, or assist us in analyzing how our
              Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
          </div>

          <div>
            <h4>SECTION 6 – SECURITY</h4>
            <p>
              We take reasonable precautions and adhere to industry’s best
              practices to protect your personal information from loss, misuse,
              unauthorized access, disclosure, alteration, or destruction.
            </p>
            <p>
              {" "}
              The security of your data is important to us, but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </div>

          <div>
            <h4>SECTION 7 – COOKIES</h4>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
            </p>
            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags, and scripts to collect and
              track information and to improve and analyze our Service.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>
              <strong>Examples of Cookies we use:</strong>
            </p>
            <ul>
              <li>
                <strong>Session Cookies:</strong> We use Session Cookies to
                operate our Service.
              </li>
              <li>
                <strong>Preference Cookies:</strong> We use Preference Cookies
                to remember your preferences and various settings.
              </li>
              <li>
                <strong>Security Cookies:</strong> We use Security Cookies for
                security purposes.
              </li>
              <li>
                <strong>Advertising Cookies:</strong> Advertising Cookies are
                used to serve you with advertisements that may be relevant to
                you and your interests.
              </li>
            </ul>
          </div>

          <div>
            {" "}
            <h4>SECTION 8 – AGE OF CONSENT</h4>
            <p>
              By using our site, you confirm that you are of legal age in your
              state or province of residence or that you have given consent for
              any of your minor dependents to use our site.
            </p>
          </div>

          <div>
            <h4>SECTION 9 – CHANGES TO THIS PRIVACY POLICY</h4>
            <p>
              We reserve the right to modify this privacy policy at any time. We
              recommend reviewing it periodically for changes and
              clarifications, which take effect immediately upon posting on our
              website. In the event of material changes, we will notify you of
              the update. If our store is acquired or merged with another
              company, your information may be transferred to the new owners to
              continue serving you.
            </p>
          </div>

          <div>
            <h4>SECTION 10 – PET HEALTH DATA MANAGEMENT</h4>

            <h5>10.1 Pet Health Data Collection and Usage</h5>
            <p>
              In addition to personal information, we may also collect and
              process health data related to your pets. This data may include
              but is not limited to your pet’s name, breed, age, weight,
              vaccination records, sterilization status, and other medical and
              general health information. We collect this information solely to
              provide the pet owner with the services offered by our app,
              including personalized pet care recommendations, health tracking,
              and appointment reminders.
            </p>

            <h5>10.2 Pet Health Data Security</h5>
            <p>
              We implement industry-standard security measures to protect the
              confidentiality and integrity of your pet's health data. These
              measures include encryption, access controls, and regular security
              audits. We restrict access to pet health data to authorized
              personnel only on a need-to-know basis.
            </p>

            <h5>10.3 Data Minimization and Purpose Limitation</h5>
            <p>
              We collect only the minimum necessary pet health data required to
              provide the services offered by our app. We use this data solely
              for the purposes stated in this Privacy Policy and do not share it
              with third parties without your explicit consent, except as
              required by law.
            </p>

            <h5>10.4 User Controls</h5>
            <p>
              You have the right to access, view, correct, or delete your pet's
              health data, and you can request its deletion at any time. We will
              retain your pet's health data only for as long as necessary to
              provide the services and as permitted by applicable law.
            </p>

            <h5>10.5 Compliance with Laws</h5>
            <p>
              We comply with all applicable data privacy laws and regulations,
              including but not limited to the Health Insurance Portability and
              Accountability Act (HIPAA), if applicable, and the General Data
              Protection Regulation (GDPR), if you are located in the European
              Union.
            </p>
          </div>

          <div>
            <h4>SECTION 11 – LOCATION INFORMATION</h4>

            <h5>11.1 Collection of Location Information</h5>
            <p>
              When using our mobile applications, we may collect and process
              information about your device’s location, including precise
              geographic data such as GPS coordinates, Wi-Fi signals, and
              Bluetooth beacons, as well as general location data such as city
              or region.
            </p>
          </div>

          <div>
            {" "}
            <h4>SECTION 12 – How We Use Your Location Information</h4>
            <p>We use location information for:</p>
            <ul>
              <li>
                <strong>Service Functionality:</strong> To provide
                location-based services, such as maps, directions, and
                location-specific features.
              </li>
              <li>
                <strong>Improving our Services:</strong> To analyze user
                behavior, track trends, and enhance our mobile applications.
              </li>
            </ul>
            <p>
              We use the Order Information that we collect generally to fulfill
              any orders placed through the app (including processing your
              payment information and providing you with invoices and/or order
              confirmations). Additionally, we use this Order Information to:
            </p>
            <ul>
              <li>Communicate with you;</li>
              <li>Screen our orders for potential risk or fraud; and</li>
              <li>
                When in line with the preferences you have shared with us,
                provide you with information or advertising relating to our
                products or services.
              </li>
            </ul>
            <p>
              We use the Device Information that we collect to help us screen
              for potential risk and fraud (in particular, your IP address), and
              more generally to improve and optimize our app (for example, by
              generating analytics about how our customers browse and interact
              with the app, and to assess the success of our marketing and
              advertising campaigns).
            </p>
          </div>

          <div>
            {" "}
            <h4>SECTION 13 – YOUR CHOICES</h4>
            <p>
              You can enable or disable location services through your device
              settings. However, disabling location services may affect the
              functionality of some app features.
            </p>
          </div>

          <div>
            {" "}
            <h4>SECTION 14 – SECURITY OF LOCATION DATA</h4>
            <p>
              While we implement reasonable safeguards to protect your location
              data, please be aware that no transmission over the Internet or
              electronic storage is entirely secure.
            </p>
          </div>

          <div>
            <h4>SECTION 15 – CHANGES TO LOCATION DATA POLICY</h4>
            <p>
              We may update this Location Data Policy within our mobile
              applications to reflect changes in our practices or for
              operational, legal, or regulatory reasons.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>

          <div>
            <h4>SECTION 16 – CONTACT INFORMATION</h4>
            <p>
              For questions, concerns, or requests related to this Privacy
              Policy or the use of location data in our mobile applications,
              please contact us at{" "}
              <a href="mailto:care@peteye.pet">care@peteye.pet</a> or by mail at
              K R PET EYE LLP, 15th Street, Himayat Nagar, Hyderabad, 500029.
            </p>
            <p>
              Thank you for choosing PetEYE for our services. We are committed
              to respecting your privacy and ensuring responsible and
              transparent handling of your information.
            </p>
          </div>

          <div>
            <h4>QUESTIONS AND CONTACT INFORMATION</h4>
            <p>
              For access to, correction of, amendment to, or deletion of your
              personal information, to register a complaint or for further
              information, please contact our Privacy Compliance Officer at{" "}
              <a href="mailto:care@peteye.pet">care@peteye.pet</a> or by mail at
              K R PET EYE LLP, 15th Street, Himayat Nagar, Hyderabad, 500029.
            </p>
          </div>
        </div>
      </div>

      <section>
        <Footer />
      </section>
    </div>
  );
}
