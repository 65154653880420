import { useEffect } from "react";

export const useTitle = (title) => {
  useEffect(() => {
    const defaultTitle = "PetEYE";
    document.title = title ? `${title}` : defaultTitle;
  }, [title]);

  console.log(title);
  return null;
};
