import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Aboutus.css";
import { Link } from "react-router-dom";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { CiGlobe } from "react-icons/ci";

const Image1 =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/Group%2B1171276804.webp",
  globe =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/image%2B16407-1.webp",
  islandimg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/IMG_20240605_110108_997%2B1.webp",
  ladygif =
    " https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/Safe-Loved-_1_.webp",
  tabladygif =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/tab-safe-Loved_1_.webp",
  mobileladygif =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/Safe-Loved-Mob_1_.webp",
  globegif =
    " https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/web+dog+and+glob.gif",
  tabglobegif =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/dog+glob+tab.gif ",
  mobileglobegif =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Aboutwebp/mobile+dog+glob.gif ";

const sec3Vid =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/add+family%40%40+-+Made+with+Clipchamp.mp4";

const Section1 = () => {
  useTitle(
    "PetEYE About: We envision a world where Every Pet is Safe and Loved."
  );
  // playvideo
  const videoRef = useRef(null);
  const [videoVisible, setVideoVisible] = useState(false);

  const resetVideo = () => {
    setVideoVisible(false);
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const handleContainerClick = () => {
    if (videoVisible) {
      resetVideo();
    }
  };

  const playVideo = () => {
    setVideoVisible(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  const handleVideoClick = (e) => {
    e.stopPropagation();
  };

  // end of video

  // animationtext
  const contents = [
    {
      title: "Mission 1:  Ensuring Safety and Loving Community",
      text: "PETEYE enhances pet safety with GPS tracking and QR code identification, fostering a loving community where pet owners connect, share experiences, and seek advice, ensuring the well-being of pets.",
    },
    {
      title: "Mission 2: Encouraging Responsible Pet Ownership",
      text: "PETEYE promotes responsible ownership through simplified pet licensing, vaccination reminders, and health monitoring tools, empowering pet owners to provide optimal care for their pets.",
    },
    {
      title: "Mission 3: Innovating Pet Health Management",
      text: "PETEYE integrates advanced health monitoring technology, offering real-time tracking of heart rate, activity levels, and behavior to detect early health issues and improve pets' quality of life.",
    },
    {
      title: "Mission 4: Expanding Access to Pet Care Services",
      text: "PETEYE offers accessible pet care services, including virtual vet consultations, grooming, and personalized training, supporting pet owners in providing comprehensive care for their pets.",
    },
  ];

  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % contents.length);
        setFade(true);
      }, 500); // Duration of fade-out
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const location = useLocation();
  useEffect(() => {
    const scrollToRef = () => {
      const element = document.getElementById(location.hash.split("#")?.[1]);
      // console.log(element)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToRef();
  }, [location]);
  return (
    <section className="w-100 scroll-snap abt" style={{ minWidth: "100vw" }}>
      <section>
        <Header />
      </section>

      {/* section1 */}

      <section className="hero container" id="home">
        <h1 className="heading1">At PetEYE, We're More Than Just a Brand</h1>
        <h2 className="heading2">
          -We're a Lifeline for Pets and Their Familes
        </h2>
        <div className="paradiv">
          <img
            src={Image1}
            alt="Mydog pet Image"
            className="about img1"
            title=""
          />
        </div>
        <p className="paragraph">
          We're on a mission to envelop every moment you share with your beloved
          pet in safety and care. it's the heartbeat of our commitment to you
          and your furry family members. Dive into our story and discover how
          we're transforming pet safety and bonding, one innovation at a time.
        </p>
      </section>

      {/* section2 */}

      <section className="mission " id="vision">
        {
          <div className="about-sec2-gif">
            <img
              src={ladygif}
              alt="peteye blinking eye"
              className="lightbulb-gif1"
              title=""
            />
            <img
              src={mobileladygif}
              alt="peteye blinking eye"
              className="lightbulb-gif2"
              title=""
            />
            <img
              src={tabladygif}
              alt="peteye blinking eye"
              className="lightbulb-gif3"
              title=""
            />
          </div>
        }
      </section>

      {/* section3 */}

      <section className="section3 container" id="mission">
        <div className="divtext">
          <img src={islandimg} title="" alt="peteye services" />
          <p className="text12">Fostering a world where every pet flourishes</p>
          <p className="text21">
            we're dedicated to widespread responsible ownership, comprehensive
            health care advanced data insights, and creating a secure,
            compassionate community for all.
          </p>
          <nav className="navbar1">
            <Link to="/about/#fetch-eco-system">
              <button className="fetchbtn">
                <CiGlobe style={{ fontSize: "2rem" }} /> &nbsp; FETCH Ecosystem
              </button>
            </Link>
          </nav>
        </div>
      </section>

      <section
        className={`black-section`}
        id="fetch-eco-system"
        onClick={handleContainerClick}
      >
        <Container>
          <div className="sec-3-row">
            <div className={`sv-sec-3-container-1`}>
              <div
                className={`video-container ${videoVisible ? "" : "hidden"}`}
                onClick={handleVideoClick}
              >
                <video
                  ref={videoRef}
                  src={sec3Vid}
                  controls
                  onEnded={resetVideo}
                  className="img-fluid"
                  alt="vid"
                  title="vid"
                />
              </div>
              {!videoVisible && (
                <div className="sv-sec-3-content">
                  <p className="small-text">Your Pet’s Complete Ecosystem</p>
                  <h2 className="big-text">FETCH Ecosystem</h2>
                  <p className="small-text2">
                    From data nurturing to health guardians, doorstep miracles,
                    and state-of-the-art devices, this is where dreams meet
                    reality.
                  </p>
                  {/* <button
                    className="secondary-btn"
                    onClick={playVideo}
                    style={{ width: "200px" }}
                  >
                    Play
                  </button> */}
                  {/* <Button variant="primary" onClick={playVideo}>Play Video</Button> */}
                </div>
              )}
            </div>
            <div
              className={`sv-sec-3-container-2 ${videoVisible ? "hidden" : ""}`}
            >
              <img src={globe} alt="Image" className="img-fluid" title="" />
            </div>
          </div>
        </Container>
      </section>

      <section>
        <div className="globegif-section">
          <div className={`animationtext fade-${fade ? "in" : "out"}`}>
            <h1>{contents[index].title}</h1>
            <p>{contents[index].text}</p>
          </div>

          <div className="globe-gif">
            <img
              className="desktop-globegif"
              src={globegif}
              alt="desktopgif"
              title=" "
            />
            <img
              className="mobile-globegif"
              src={mobileglobegif}
              alt="mobgif"
              title=" "
            />
            <img
              className="tab-globegif"
              src={tabglobegif}
              alt="tabgif"
              title=" "
            />
          </div>
        </div>
      </section>

      <Footer />
    </section>
  );
};
export default Section1;
