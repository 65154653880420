import React, { useEffect } from "react";
import "./CareerPage.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { MdOutlineWatchLater } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { GrMail } from "react-icons/gr";
import { useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardArrowRight,
} from "react-icons/md";
import {
  RiLinkedinFill,
  RiTwitterXFill,
  RiInstagramFill,
} from "react-icons/ri";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTitle } from "../../hooks/useTitle";

const teamMembers = [
  {
    id: 1,
    name: "Koushik Gaja",
    designation: "Managing partner",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/koushik1.webp",
    mail: "koushik@peteye.pet",
    twitterlink: "https://x.com/koushikgaja",
    linkedinlink: "https://uk.linkedin.com/in/koushikgaja",
    Instagramlink: "https://www.instagram.com/koushikgaja/?hl=en",
  },
  {
    id: 2,
    name: "Goverdhan Reddy",
    designation: "Director of Operations",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/govardhan1.webp",
    mail: "goverdhan.lakkadi@gamil.com",
    linkedinlink:
      "https://www.linkedin.com/in/goverdhan-lakkadi-843a71b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    Instagramlink:
      "https://www.instagram.com/goverdhanreddy?igsh=dXNwaG0xbXp4cWl1&utm_source=qr",
  },
  {
    id: 3,
    name: "Subhashini Kannan",
    designation: "UI/UX Designer",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/subhashini.webp",
    mail: "subhakannan1722@gmail.com",
    linkedinlink:
      "https://www.linkedin.com/in/subhashini-kannan-9592a824a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    Instagramlink: "https://www.instagram.com/kgr_subha?igsh=bTdqcGp1djlzMDFi",
  },
  {
    id: 4,
    name: "Mohit Kuril",
    designation: "Front-End Developer",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/mohit1.webp",
    mail: "mohitkuril5@gmail.com",
    twitterlink: "https://x.com/Mohitkuril5?t=RDUpbm1Gp6kEigUFpXdLIQ&s=09",
    linkedinlink:
      "https://www.linkedin.com/in/mohit-kuril-84884926b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    Instagramlink:
      "https://www.instagram.com/mohitkuril5?igsh=dW9qNnZ6Z2FjaWQ1",
  },
  {
    id: 5,
    name: "Jithun Madhav",
    designation: "Web Developer",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/jithun1.webp",
    mail: "jithunmadhavpeteye@gmail.com",
    twitterlink: "https://x.com/jithun_madhav?t=cTNUMIcBG0R_8uQgwxo-JA&s=09",
    linkedinlink: "https://www.linkedin.com/in/jithunmadhav/",
    Instagramlink: "https://www.instagram.com/jithun_madhav_/",
  },
  {
    id: 6,
    name: "Mahesh",
    designation: "Mobile Developer",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/mahesh1.webp",
    mail: "mahesh.mamidisetti@peteye.co.in",
    linkedinlink:
      "https://www.linkedin.com/in/mahesh-mamidisetti-973b8a2b7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    Instagramlink:
      "https://www.instagram.com/_maheshuuu_?igsh=M25qanF2NXAyMW1x",
  },
  {
    id: 7,
    name: "Vaibhav Kumar",
    designation: "Front-End Developer",
    image:
      "https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/vaibhav1.webp",
    mail: "vaibhav.pandey1508@gmail.com",
    twitterlink: "https://twitter.com/SyntaxError408",
    linkedinlink: "https://www.linkedin.com/in/devxvaibhav",
    Instagramlink:
      "https://www.instagram.com/incognito_.life?igsh=MWg0aHNxMnRyYjMwZA==",
  },
];
const jobsData = [
  {
    id: 1,
    title: "UI/UX Designer",
    experience: "2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Design",
  },
  {
    id: 2,
    title: "Graphic Designer",
    experience: "2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Design",
  },
  {
    id: 3,
    title: "3D Designer",
    experience: "2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Design",
  },
  {
    id: 4,
    title: "React Js Developer",
    experience: "2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Developers",
  },
  {
    id: 5,
    title: "Node Js Developer",
    experience: "2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Developers",
  },
  {
    id: 6,
    title: "Mobile Developer",
    experience: "2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Developers",
  },
  {
    id: 7,
    title: "React Js Intern",
    experience: "0 - 2 years Experience",
    type: "Full-Time",
    location: "Hybrid/Remote",
    time: "Full Time",
    category: "Developers",
  },
];
const CareerPage = () => {
  useTitle(`PetEYE Careers: Dreaming Beyond Boundaries, Living with Purpose`);
 

  const location = useLocation();
  useEffect(() => {
    const scrollToRef = () => {
      const element = document.getElementById(location.hash.split("#")?.[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToRef();
  }, [location]);

  const { section } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="scroll-snap carrer-page" style={{ minWidth: "100vw" }}>
      <Header />
      <section className="career" id="careerhome">
        <div className="career-section">
          <h1>Careers</h1>

          <img
            src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Carrerpagewebp/CareersFamilyWebp.webp"
            alt=""
          />

          <h2>Dreaming Beyond Boundaries, Living with Purpose</h2>
          <p>
            As a fledgling in the pet industry, PetEye is rapidly expanding. We
            take immense pride in our cutting-edge technology and the talented
            team that drives our success. If you're seeking a new opportunity to
            make waves in the pet world, you're in the right place.
          </p>
        </div>
      </section>
      <section className="career-sec2" id="carrer-part2">
        <div className="team-profile-heading">
          <h2>
            Quality Individuals, Fantastic Environment, & Brilliant Mindset
          </h2>
          <p>
            Our culture fosters innovation, collaboration, and a
            forward-thinking mindset. Join us and be part of a community where
            excellence is the norm and brilliance is celebrated.
          </p>
        </div>
        <div className="team-profile">
          <div className="carousel-container">
            <div className="scrolling-wrapper">
              <div class="slider">
                <div class="slide-track">
                  {[...teamMembers, ...teamMembers].map((member, index) => (
                    <div className="card1" key={`${member.id}-${index}`}>
                      <div className="image-container">
                        <img
                          src={member.image}
                          alt={`${member.name} - ${member.designation}`}
                        />
                        <div className="designation">
                          <p>{member.name}</p>
                          <p>{member.designation}</p>
                          <div className="team-social-media">
                            <a
                              href={member.linkedinlink}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                color: "white",
                              }}
                            >
                              <RiLinkedinFill />
                            </a>
                            {member.twitterlink && (
                              <a
                                href={member.twitterlink}
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                <RiTwitterXFill />
                              </a>
                            )}
                            <a
                              href={member.Instagramlink}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                color: "white",
                              }}
                            >
                              <RiInstagramFill />
                            </a>
                            <a
                              href={`mailto:${member.mail}`}
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                color: "white",
                              }}
                            >
                              <GrMail />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="career-sec3" id="carrer-vacancy">
        <div className="career-sec2-part1">
          <h3>Be Part of Peteye Family </h3>
          <p>
            As a fledgling in the pet industry, PetEye is rapidly expanding. We
            take immense pride in our cutting-edge technology and the talented
            team that drives our success. If you're seeking a new opportunity to
            make waves in the pet world, you're in the right place.
          </p>
        </div>
        <div className="career-sec2-part2">
          <div className="carrer-sec2-child1">
            <h4>Frontend Developer</h4>
            <p>
              They should have knowledge and experience in react js and
              integrating with backend technologies like node.js.
            </p>

            <a href="#developer">Apply Now</a>
          </div>
          <div className="carrer-sec2-child1">
            <h4>UI/UX Designer</h4>
            <p>
              They should have knowledge and experience in FIGMA and design
              software's such as Framer X & Adobe Suite.
            </p>
            <a href="#uiux">Apply Now</a>
          </div>
          <div className="carrer-sec2-child1">
            <h4>Backend Developer</h4>
            <p>
              They should have knowledge and experience in Mongo DB / MySQL,
              Express JS, React JS, & Node JS.
            </p>
            <a href="#developer">Apply Now</a>
          </div>
        </div>
      </section>

      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            fontSize: "1.2rem",
            color: "black",
          }}
        >
          Scroll Down <MdKeyboardDoubleArrowDown />
        </h3>
      </div>

      <section className="sec-4">
        <div className="carrer-sec3-job-part">
          <div className="carrer-sec3-design" id="uiux">
            <h2>Design Team</h2>
            <p> Open Positions in our design team</p>
          </div>
          <div className="carrer-sec3-vacancy-part">
            {jobsData
              .filter((job) => job.category === "Design")
              .map((job) => (
                <div key={job.id} className="job-listing">
                  <div className="job-listing-role">
                    <h3>{job.title}</h3>
                    <div className="job-listing-location">
                      <p>
                        {" "}
                        <MdOutlineWatchLater /> {job.time}
                      </p>
                      <p>
                        <IoLocationOutline />
                        {job.location}
                      </p>
                    </div>
                  </div>
                  <div className="job-listing-experience">
                    <p>{job.experience}</p>
                  </div>
                  <div>
                  <a href="https://forms.gle/5wZjQQqfqyVtkXgX9 " target="_blank" rel="noopener noreferrer">
                  Apply
                  </a>                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="carrer-sec3-job-part">
          <div className="carrer-sec3-design" id="developer">
            <h2>Developer Team</h2>
            <p> Open Positions in our Developers team</p>
          </div>
          <div className="carrer-sec3-vacancy-part">
            {jobsData
              .filter((job) => job.category === "Developers")
              .map((job) => (
                <div key={job.id} className="job-listing">
                  <div className="job-listing-role">
                    <h3>{job.title}</h3>
                    <div className="job-listing-location">
                      <p>
                        <MdOutlineWatchLater /> {job.time}
                      </p>
                      <p>
                        <IoLocationOutline />
                        {job.location}
                      </p>
                    </div>
                  </div>
                  <div className="job-listing-experience">
                    <p>{job.experience}</p>
                  </div>
                  <div>
                  <a href="https://forms.gle/5wZjQQqfqyVtkXgX9 " target="_blank" rel="noopener noreferrer">
                  Apply
                  </a>                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section>
        <div className="career-contact">
          <h2>Can't find a role that suits you?</h2>
          <p>We’re always looking for smart, motivated people.</p>
          <p>Send us your CV and let’s have a chat. We’d love to meet you.</p>

          <a href="mailto:careers@peteye.pet">
            <button>
              Contact Us <MdKeyboardArrowRight className="button-icon" />
            </button>
          </a>
        </div>
      </section>

      <Footer />
    </section>
  );
};

export default CareerPage;
