import axios from "axios";
 

const instance = axios.create({
 
 
  baseURL:' https://devbackendapi.peteye.pet/',
 
  // baseURL:'http://192.168.29.181:2000/',
 

     
  });

  export default instance;